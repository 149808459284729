import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import ImageList, { imageListStyle } from '~components/ImageList';
import Text from '~components/Text';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../../enums';

const WorkforcePage: React.FC = () => (
  <Layout>
    <SEO title="Workforce Development" desc="" pathname="/aspirations/workforce-development" />
    <Hero title="Lead in workforce development">
      <StaticImage src="../../images/people-in-workshop.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container mb-24">
      <div tw="max-w-5xl">
        <Text>
          As a catalyst for community and economic resiliency, we are committed to developing and
          implementing transformative educational models to support workforce development, from
          small business through to large industry and sector partners.
        </Text>
      </div>
    </div>
    <ImageList
      title="Strategic Direction"
      items={[
        'Lead regional efforts to strengthen workforce strategies in collaboration with the Cities of Hamilton and Burlington that will support recovery and future economic development priorities.',
        'Scale-up the reach of the Future Ready Premium Partner program and Challenge 2025 to accelerate the impact of demand-led employment solutions, contributing to a highly skilled and agile workforce.',
        'Amplify and expand our career support services, including Alumni, and create a virtual global learning program, to further develop student opportunities, and to track graduate outcomes and employment.',
        'Through the optimization of technology, partnerships and delivery modes, develop and incorporate new work-integrated learning opportunities in programs and learning experiences.',
        'Forge exceptional funder relationships with all levels of government to support targeted strategic initiatives, increase reach and create measurable impact.'
      ]}
    >
      <StaticImage
        src="../../images/students-working-on-computers.jpg"
        alt=""
        layout="fullWidth"
        style={imageListStyle}
      />
    </ImageList>
    <div tw="mb-24 bg-orange text-gray-800 py-12">
      <div tw="container">
        <div tw="justify-between space-x-6 items-center md:(flex)">
          <Bag tw="h-32 w-32 flex-shrink-0 md:(order-2 h-48 w-48)" />
          <div tw="md:(order-1)">
            <Heading level="h2" tw="mt-0">
              Leadership Outcome
            </Heading>
            <Text tw="text-2xl font-bold">
              We will lead Canada’s postsecondary sector in advancing an inclusive economic and
              workforce recovery, by building national alliances and partnerships that leverage
              proven models and innovative solutions to generate positive collective impacts for all
              Canadian communities.
            </Text>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="max-w-5xl mb-12">
        <Heading level="h2" tw="text-rose">
          Building on Momentum
        </Heading>
        <Text tw="text-xl font-bold">
          We have demonstrated we can mobilize and accelerate action to meet the immediate needs of
          employers and the community with demand-led initiatives like City School and Challenge
          2025 that enhance economic recovery and prepare workers for the jobs of the future.
        </Text>
        {/* <Heading level="h3">1. Mohawk first</Heading>
        <Text>
          Our Future Ready Premium Employer Program is a new approach to prepare students for future
          jobs. Mohawk is working with 10 longstanding partners to bridge the gap to careers.
          Together, the college and employer define future workforce needs and develop relevant
          curriculum and programs to meet them.
        </Text>
        <Heading level="h3">2. Mohawk best</Heading>
        <Text>
          Challenge 2025, a workforce development project, added industry-driven training to the
          City School model to build relevant skills for careers with employers who are partners.
          Future Skills Canada has provided funding for a new industry training program in the
          supply chain sector using this model. The pilot includes developing a replication strategy
          to share with communities across the country.
        </Text> */}
      </div>
      <StyledHeading>Aspirations</StyledHeading>
      <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/truth-reconciliation"
          Icon={<Directions tw="w-12" />}
        >
          Meaningfully advance Truth and Reconciliation
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/future-ready" Icon={<Check tw="w-12" />}>
          Enable Future Ready education, research, programs, services and experiences – for
          students, employees and our community
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/celebrate-every-person"
          Icon={<Access tw="w-12" />}
        >
          Be a place that honours, values and celebrates the whole of every person
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/education" Icon={<Pointer tw="w-12" />}>
          Ensure access to education and reduce barriers for all
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/climate-change" Icon={<Globe tw="w-12" />}>
          Make a measurable impact on climate change
        </IconCard>
      </div>
    </div>
  </Layout>
);

export default WorkforcePage;
